#root {
  height: 100%;
}

* {
  outline: 0;
}

html,
body {
  position: relative;
  height: 100%;
}
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;500;700;900&display=swap");

body {
  background: #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-family: "Red Hat Display", sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
