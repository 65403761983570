.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  text-align: left;
  gap: 6px;
}

.bg-title {
  font-weight: 900;
  font-size: 36px;
}

.md-title {
  font-size: 24px;
  font-weight: 500;
}

.title {
  font-size: 20px;
  font-weight: 300;
}

.wdata-logo {
  max-width: 300px;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.prev-button {
  margin-top: 10px;
  width: 50px;
  height: 50px;
  font-size: 20px;
  font-weight: 900;
  border-radius: 5px;
  font-family: "Red Hat Display", sans-serif;
  background-color: rgba(0,0,0,.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 300ms all;
  color: white;
}

.next-button {
  margin-top: 10px;
  padding: 0 25px;
  height: 50px;
  font-size: 20px;
  font-weight: 900;
  border-radius: 5px;
  font-family: "Red Hat Display", sans-serif;
  background-color: rgba(0,0,0,.5);
  color: white;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 300ms all;
}

input[type="text"] {
  font-family: "Red Hat Display", sans-serif;
  font-size: 20px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid grey;
  color: white;
  height: 50px;
  width: 100%;
  transition: 300ms all;
}
/* input[type="text"]:focus {
  border-bottom: 1px solid white;
} */

.text-field {
  width: 100%;
  font-size: 20px;
}

.next-button:hover {
  background-color: rgb(0, 0, 0,.8);
}

/* Standard syntax */
@keyframes swipe {
  0% {
    margin: 0;

    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-10deg);
  }

  from {
    top: 0px;
  }
  to {
    top: 50px;
  }
}

.handswipe {
  animation: swipe 0.9s alternate infinite;
  animation-timing-function: ease-in-out;

  position: relative;
  width: 75px;
  left: 60%;
  bottom: 0;
}

@media screen and (max-width: 560px) {
  .content {
    width: 75%;
  }
  .wdata-logo {
    max-width: 250px;
  }

  .handswipe {
    /* margin-top: 300px; */
    width: 50px;
    left: 50%;
  }
}
