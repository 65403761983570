.swiper {
  width: 100%;
  height: 100%;
  background-color: white;
  background-image: url(../../assets/back.png);
  background-position: top right; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: black;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  z-index: 999999;
}

.navbar {
  box-sizing: border-box;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  background-color: rgba(255,255,255,.6);
  color: black;
}

.navbar>.navbar-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.navbar img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.navbar>.navbar-exit {
  padding: 3px 8px;
  height: 30px;
  font-weight: 900;
  border-radius: 5px;
  font-size: 15px;
  font-family: "Red Hat Display", sans-serif;
  background-color: transparent;
  color: black;
  cursor: pointer;
  outline: none;
  border: none;
  transition: 300ms all;
}

.navbar>.navbar-exit:hover {
  background-color: rgb(189, 189, 189);
  color: black;
}

.custom-select {
  position: relative;
  display: block;
  width: 100%;
  background-color: rgba(255,255,255,.6);
  z-index: 10;
  border-radius: 5px;
}

.custom-select * {
  font-size: 18px;
  background-color: rgba(255,255,255,.6);
  font-weight: bold;
}

.custom-select select {
  border: none;
  outline: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  margin: 0;
  display: block;
  width: 100%;
  padding: 17px 1vh 14px 2vh;
  font-size: 18px;
  color: black;
  font-family: "Mulish", sans-serif;
  font-weight: 900;
  text-align: left;
}

.submit-button {

  margin-top: 10px;
  padding: 0 25px;
  height: 50px;
  font-size: 20px;
  font-weight: 900;
  border-radius: 5px;
  font-family: "Red Hat Display", sans-serif;
  background-color: rgba(24, 24, 24,.97);
  cursor: pointer;
  outline: none;
  border: none;
  transition: 300ms all;
  color: white;

}

.image-content {
  width: 110px;
  height: 90px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
}

.wnext-logo {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: 75px;
  transition: 500ms all;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

label {
  position: relative;
}

label::before {
  content: " ";
  background: url(../../assets/arrow-down.svg) no-repeat center ;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
}

.wnext-logo.hidden {
  opacity: 0;
  visibility: hidden;
  transform: translateY(900px);
}

.wdata-logo {
  max-height: 150px;
}

@media screen and (max-width:600px) {
  .wdata-logo {
    width: 250px;
  }

  .wnext-logo {
    width: 50px;
  }
}

::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  background: #181818;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #383838;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.weather {
  color: #fdd10c;
  background-color: rgba(24, 24, 24,.97);
  border-radius: 5px;
  padding: 4px 10px;
}

.temperature {
  color: black;
}